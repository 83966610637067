import React, { useState} from 'react';
import { Grid, Button, Slide, Dialog, DialogContent, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './RequestFormSubsPopup.scss';
import GreenArrow from '../../assets/Green-arrow-right.svg';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='left' ref={ref} {...props} />;
// });

export default function RequestFormSubsPopup() {
  const [open, setOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid elevation={0} className='request-form-btn-subs'>
      {showTopBtn && <Button className={open ? 'request-form-btn open' : 'request-form-btn'} style={{background:"#173159"}}>
        
        <Box sx={{ display: 'flex',alignItems:'center' }}>
        <div onClick={() => setOpen(!open)}> 
        {open ?
          <img src={GreenArrow} alt="arrow" className='arrow-icon right' /> :
          <img src={GreenArrow} alt="arrow" className='arrow-icon left' />
        }
        </div>  
        <div>
          subscribe to our blog
        {open &&
        <div className="subscribeForm">
          <iframe src='https://resources.cartus.com/l/688373/2022-12-05/8swqm' width='100%' height='100%' type='text/html' frameborder='0' allowTransparency='true' />
        </div>
        }
        </div>
        
        
        </Box>

      </Button>
      }

      
      {/* <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth='md'>
        <Box className='request-form-popup-overlay'>
          <div className='bg-donut'>
            <IconButton className='popup-close-icon' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogContent className='dialog-content'>
              <Grid container >
                <Grid items xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
                <Grid items xl={6} lg={6} md={12} sm={12} xs={12}>
                  <div className='title'>Request for more information</div>
                  <p className='desc'>Information includes all materials, collaterals, demo links as applicable</p>
                </Grid>
                <Grid items xl={5} lg={5} md={12} sm={12} xs={12} className='form-div'>
                  <img src={request_form} />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </Box>
      </Dialog> */}
     
    </Grid>
  )

}
