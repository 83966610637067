import React from 'react';
import Layout from '../../staticcomponents/layout';
import RequestFormSubsPopup from '../../staticcomponents/RequestFormSubsPopup';
import './subscribe.scss';


export default function Campaign(){
    return (
        <>
        <Layout>
            <div className='campaign-cls '>
            <h1>Request form Subscribe</h1>
                <RequestFormSubsPopup/>
            </div>
            </Layout>
        </>
    )
}